import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import powered_logo from '../../assets/images/powered_logo.png';
import agent from '../../agent';
import Loader from '../components/Loader';
import { FETCH_ALL_AGENTS } from '../../constants/actionTypes';
import { setSelectedAgentData } from "../../reducers/slices/headerSlice";
const mapStateToProps = (state) => ({
  ...state,
  allAgents: state.common.allAgents,
});

const mapDispatchToProps = (dispatch) => ({
  onLoadPage: async () => {
    try {
      const response = await agent.common.getAllAgents();
      const data = response; // Assuming the data is within the response object
      dispatch({
        type: FETCH_ALL_AGENTS,
        payload: data,
      });
    } catch (error) {
      // Handle error here if needed
    }
  },
});

const MainView = (props) => {
  const { allAgents, onLoadPage, handleNextStep } = props;
  const [agents, setAgents] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState('');
  const [inProgress, setInProgres] = useState(true);
  const [click, setClick] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [agentVisibility, setAgentVisibility] = useState({});
  const dispatch = useDispatch();



  const mainHandler = (value)=>{
    handleRadioChange(value)
  }

  const handleRadioChange = (value) => {
    setSelectedUrl(value);
    setDisabled(false);
    const selectedAgent = agents.find((agent) => agent.integrate.calendlyLink === value);
    dispatch(setSelectedAgentData(selectedAgent));
  };

  const handleNext = (selectedUrl) => {
    if (!disabled) {
      if (selectedUrl) {
        handleNextStep(selectedUrl);
        setDisabled(false);
        setClick(false);
      }
    }
    else {
      setClick(true);
      setDisabled(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await agent.common.getAllAgents();
        const data = response;
        dispatch({
          type: FETCH_ALL_AGENTS,
          payload: data,
        });
      } catch (error) {
      } finally {
        setInProgres(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (allAgents) {
      setAgents(allAgents);

    }
  }, [allAgents]);

  const toggleBio = (event, id) => {
    // event.stopPropagation();
    setAgentVisibility((prevVisibility) => ({
      ...prevVisibility,
      [id]: !prevVisibility[id],
    }));
  };

  const nextButtonStyle = { backgroundColor: selectedUrl ? '#219653' : '#F2F2F2', color: selectedUrl ? "#ffffff" : "#BDBDBD" };

  return (
    <Fragment>
      <section id="Schedule-main">
        <div className="container">
          <div className="schedule">
            {inProgress ? (
              <Loader />
            ) : (<>
              <div className="schedule-row">
                <div className="Schedule-head-col">
                  <div className="Schedule-head">
                    <h1>Schedule a call</h1>
                    <div className="powered">
                      <span>Powered by:</span>
                      <img src={powered_logo} alt="" />
                    </div>
                  </div>
                </div>
                <div className="select-col">
                  <div className="select-agent">
                    <h3>Select agent</h3>
                  </div>
                </div>
              </div>
              <div className="main-agents">
                <div className="schedule-row">
                  {agents && agents.length ? (
                    agents.map((agent, i) => (
                      <Fragment key={i}>
                        <div className="agents-col" onClick={()=>mainHandler(agent.integrate.calendlyLink)}>
                          <div
                            className={`agents ${selectedUrl === agent.integrate.calendlyLink
                              ? 'agent-active'
                              : ''
                              }`}
                          >
                            <input
                            
                              type="radio"
                              value={agent.integrate.calendlyLink}
                              checked={selectedUrl === agent.integrate.calendlyLink}
                              onChange={(event)=>handleRadioChange(event.target.value)}
                            />
                            <div className='agent-profile'>
                              <img
                                src={agent.image}
                                alt="Agent image"
                                width={50}
                                height={50}
                              />
                            </div>
                            <div className='agent-bio'>
                              <h2>{agent.firstName + ' ' + agent.lastName}</h2>
                              <span>{agent.location}</span>
                              <div></div>
                              <div className="bio-content">
                                <p>
                                  {agentVisibility[agent.id] ? agent.shortBio : agent.shortBio?.split(' ').slice(0, 9).join(' ')}
                                  <button onClick={(e) => toggleBio(e, agent.id)} className='toggle-button'>
                                    {agentVisibility[agent.id] ? '...Read less' : '...Read more'}
                                  </button>
                                </p>

                              </div>
                            </div>

                            {selectedUrl === agent.integrate.calendlyLink ? (
                              <i className="fa fa-check-circle" aria-hidden="true"></i>
                            ) : null}
                          </div>
                        </div>
                      </Fragment>
                    ))
                  ) : null}
                  <div className="next-btn-col">
                    <div className="next-btn">
                      <Link disabled={disabled} to="#" onClick={() => { handleNext(selectedUrl) }} style={nextButtonStyle}> Next</Link>
                    </div>
                    {click && !selectedUrl && (
                      <p className='error'>Please choose an agent before proceeding</p>
                    )}
                  </div>
                </div>
              </div> </>)}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
