import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import bnrImage from "../../assets/images/compress/bg-img-c.png";
import cookies from "../../assets/images/cookie_img.png";

import {
  setResetComplete,
  setResetPass,
  setShowForgot,
  setShowLogin,
  setShowRegister,
  setVerifyOtp,
} from "../../reducers/slices/headerSlice";
import Visiter from "../Common/Visiter";
import GiftPopup from "../Common/GiftPopup";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TeamSection from "./TeamSection";
import Overview from "./Overview";
import WhyInspireMeadows from "./WhyInspireMeadows";
import Advisor from "./Advisor";
import Pets from "./Pets";
import Purchase from "./Purchase";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({});

const MainView = (props) => {
  const videoRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [showImage, setShowImage] = useState(true);
  const [showCookies, setShowCookies] = useState(false);
  const [visiterTab, setVisiterTab] = useState(false);
  const [giftPopupToggle, setGiftPopupToggle] = useState(false);
  const location = useLocation()


  const dispatch = useDispatch();
  const handleShowLogin = () => {
    dispatch(setShowLogin(true));
    dispatch(setShowRegister(false));
    dispatch(setResetPass(false));
    dispatch(setShowForgot(false));
    dispatch(setVerifyOtp(false));
    dispatch(setResetPass(false));
    dispatch(setResetComplete(false));
  };

  useEffect(() => {
    const cookieValue = localStorage.getItem("cookies");
    const visiterGift = localStorage.getItem("visiterGift");
    if (!visiterGift && cookieValue) {
      setVisiterTab(true);
    }
    if (!cookieValue) {
      // handleShowLogin()
      setShowCookies(true);
      // localStorage.setItem("cookies",true);
    }
  }, []);



  const handleClose = () => {
    setShowCookies(false);
    setVisiterTab(true);
    localStorage.setItem("cookies", false);
    // localStorage.removeItem("cookies");
  };
  const handleAccept = () => {
    setShowCookies(false);
    setVisiterTab(true);
    localStorage.setItem("cookies", true);
  };

  const togglePlayPause = () => {
    setShowImage(false);
    setIsPlaying(true);
    const video = videoRef.current;

    if (!video) {
      return;
    }

    if (video.paused) {
      video
        .play()
        .then(() => {
          setShowImage(false);
          setIsPlaying(true);
          video.controls = true;
        })
        .catch((error) => {
          console.error("Video playback error:", error);
          video.controls = false;
        });
    } else {
      video.pause();
      setIsPlaying(false);
      video.controls = false;
    }
  };



  const handleVideoEnded = () => {
    setIsPlaying(false);
    setShowImage(true);
  };

  const crossHandle = () => {
    localStorage.setItem("visiterGift", true);
    setVisiterTab(false);
    setGiftPopupToggle(false);
  };

  const showGiftPopup = () => {
    setGiftPopupToggle(true);
    setVisiterTab(false);
  };

  return (
    <Fragment>
      {visiterTab && (
        <Visiter crossHandle={crossHandle} showGiftPopup={showGiftPopup} />
      )}
      {giftPopupToggle && (
        <GiftPopup
          crossHandle={crossHandle}
          setGiftPopupToggle={setGiftPopupToggle}
        />
      )}
      <section id="banner-main">
        {showImage ? (
          <img
            className="banner-img"
            src={bnrImage}
            alt="Placeholder Image"
            width="100%"
            height="auto"
          />
        ) : (
          <video
            className="banner-img"
            width="100%"
            controls
            height="auto"
            ref={videoRef}
            onEnded={handleVideoEnded}
            autoPlay
            preload="auto"
          >
            <source src={"https://meadows-bucket-production.s3.eu-west-1.amazonaws.com/homeVideo/NewHomepageVideo.mp4"} type="video/mp4" />
          </video>
        )}

        <div className="banner-text-main">
          <div className="container">
            <div className="banner-text">
              <h1>Welcome to Inspired Meadows</h1>
              <div className="play-link" onClick={togglePlayPause}>
                <i
                  className={`fa ${isPlaying ? "fa-pause" : "fa-play"}`}
                  aria-hidden="true"
                ></i>{" "}
                {isPlaying ? "Pause Video" : "Watch Video"}
              </div>
            </div>
          </div>
        </div>
      </section>

     <Overview/>
     <WhyInspireMeadows/>

     <Purchase/>

     <Advisor/>

      <Pets />
     <TeamSection/>
      {showCookies && (
        <div>
          <div
            style={{ position: "fixed" }}
            className={`overlay-cookie ${showCookies ? "logout-active" : ""}`}
            // className={`${showCookies ? 'logout-active' : ''}`}
          />
          <div class="cookie-main">
            <img src={cookies} alt="cookies" />
            <span>Cookies Consent</span>
            <p>
              We use cookies to enhance your browsing experience and provide
              personalized content. By clicking "Accept" you agree to our use of
              cookies.
            </p>
            <ul>
              <li>
                <span  class="no-btn" onClick={handleClose}>
                  Reject
                </span>
              </li>
              <li>
                <span  class="active yes-btn" onClick={handleAccept}>
                  Accept
                </span>
              </li>
            </ul>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
