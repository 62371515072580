import { useEffect } from "react";
import SEO from "../SEO/SEO"
import MainView from "./MainView"
import useDocumentTitle from "../hooks/UseDocumentTitle";

const Products = (props) => {

  useDocumentTitle("Browse Burial Plots - Urn Plots - Flowers")
  
  useEffect(() => {
    // Load the Mapbox GL JS script
    const mapboxScript = document.createElement('script');
    mapboxScript.src = 'https://api.mapbox.com/mapbox-gl-js/v3.0.1/mapbox-gl.js';
    mapboxScript.async = true;
    document.body.appendChild(mapboxScript);

    // Load the Mapbox GL JS stylesheet
    const mapboxStylesheet = document.createElement('link');
    mapboxStylesheet.href = 'https://api.mapbox.com/mapbox-gl-js/v3.0.1/mapbox-gl.css';
    mapboxStylesheet.rel = 'stylesheet';
    document.head.appendChild(mapboxStylesheet);

    // Clean up the script and stylesheet when the component is unmounted
    return () => {
      document.body.removeChild(mapboxScript);
      document.head.removeChild(mapboxStylesheet);
    };
  }, []);

  return (
    <div className="products-page">
      <SEO
        title="Browse Burial Plots - Urn Plots - Flowers"
        description="Browse Inspired Meadows selection of eco-friendly products. Our offerings support sustainable memorialization for a respectful farewell."
        keywords="Biodegradable caskets, Eco-friendly burials, Sustainable memorialization"
         href="https://www.inspiredmeadows.co.uk/products"
      />
     

      <MainView />
    </div>
  )
}

export default Products